@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap" rel="stylesheet');

* {
  font-family: 'Roboto' , sans-serif;
  margin: 0; padding: 0;
  box-sizing: border-box;
  text-transform: capitalize;
  transition: .2s linear;
}
*::after,
*::before {
  margin: 0;
  box-sizing: border-box;
}
h1{
  text-align: center;
  margin: 5%;
}
html {
  font-size: 62.5%;
}
body {
  font-size: 150%;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: rgb(233, 230, 230);
  font-weight: bolder;
  font-size: larger;
}
.header {
  display: flex;
  justify-content: space-between; 
  padding-top: 10px;
}
.logo{
  margin-left: 2%;
  width:150px;
  height: 100%;
  border-radius: 20px;
  margin-top: 10px;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 8vh;
  background: black
}
.nav.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: black;
}

.nav__brand {
  text-transform: uppercase;
}

.nav__menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 15rem;
}
.nav__toggler {
  display: none;
}
.nav__toggler div {
  width: 2.5rem;
  height: 0.2rem;
  margin: 0.4rem;
  background: rgb(204, 204, 204);
  transition: 0.3s ease-in;
}

@media screen and (max-width: 600px) {
  .nav__toggler {
    display: block;
    cursor: pointer;
    z-index: 1000; /* Ensure the toggler appears above the content */
  }
  .nav__menu {
    position: fixed;
    padding: 20px;
    top: 24%;
    left: 0; /* Center the navbar horizontally */
    width: 100vw; /* Occupy the entire screen width */
    height: 60%;
    gap: 1px;
    background: black;
    flex-direction: column;
    transform: translateX(-100%); /* Initially off-screen to the left */
    transition: 0.5s ease-in;
    z-index: 1000; /* Ensure the menu appears above the content */
  }
}


/* Active Class */
.nav__active {
  transform: translatey(0%);
}


/* Toggle Icon Animation */

.toggle .line1 {
  transform: rotate(-45deg) translate(-4px, 5px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-4px, -5px);
}
/* -----------------------------------phone---------------------------- */

.myNum{
  color: black;
}
.phoneNumber {
  padding-right:50px;
  padding-top: 10px;
  margin-bottom: 2px;
  text-align: center;
  color: black;
  background-color: white;
  font-family:Helvetica,Arial,Verdana,sans-serif;
  
}

.social-media-top{
  margin-left: 680px;
  padding-bottom: 1px;

  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.social-media-top a {
  margin: 0 10px;
  transition: transform 0.3s ease;
}

.social-media-top a:hover {
  transform: translateY(-5px);
}

.phoneIcon {
color: black;
padding-top: 7px;
}
 .book-now {

  position: relative;
  display: inline-block;
  margin: 20px;
  padding: 15px 30px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  text-decoration: none;
  color: #060000;
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 2px solid #ed0e0e;
  border-radius: 10px;
  box-shadow: inset 0 0 0 0 #ed0e0e;
}

button:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 #040000;
}

button:active {
  transform: scale(0.9);
}

/* -----------------------Areas covered------------------------------ */

.checkLocation{

justify-content: center;
margin-left: 34%;
padding: 20px;
font-size: 28px
}

.checkLocationButton{
  
  display: inline-block;
  background: #333;
  color: #fff;
  font-size: 17px;
  border-radius: 5px;
  padding: 8px 25px;
  margin-left: 180px;
}



/* ---------------------------Courses------------------------------------ */

.homeImgContainer {
  height: 800px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.learnerImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.about .row {
  display: flex;
  align-items: center;
  background: var(black);
  
}

.about .row .image{
  flex: 1 1 45rem;
}

.about .row .image .imgg{
  
  width:85%;
  height:480px;
  display: flex;
  margin-left: 50px;
  padding: 20px;
  border-radius: 40px;
}
.about .row .content{
  flex: 1 1 45rem;
  padding: 2rem;
  
}
.about .row .content h3{
  color: #444;
  font-size: 28px;
  padding: 10px 0;
}
.about .row .content p{
  font-size: 3 rem;
  padding: 1rem 0;
  line-height: 2.0;
  
}
.about .row .content .btn{
  margin-top: 10px;
  display: inline-block;
  background: #333;
  color: #fff;
  font-size: 17px;
  border-radius: 5px;
  padding: 8px 25px;
}

.heading{

  text-align: center;
  padding-bottom: 3.5rem;
  color: #a3a3a3;
  text-shadow: 0 5px 10px rgba(0,0,0,.2);
  font-size: 40px;
  text-transform:uppercase
}



.areas .heading{

  text-align: center;
  padding-bottom: 3.5rem;
  color: #a3a3a3;
  text-shadow: 0 5px 10px rgba(0,0,0,.2);
  font-size: 40px;
  text-transform:uppercase;
  padding-top: 20px;
}

.lists{
  display: flex;
  box-shadow: 0 5px 10px rgba(0,0,0,.2);
  background: #fff;
  text-align: center;
  padding: 30px 20px;
}

.areas li::before{ 
  content: '\1F697';
  font-size: 20px;
  color: #444;
  text-shadow: 0 0 5px #444, 0 0 10px #444;
  margin-left: 5px;
  margin-top: 10px;
  padding-right: 5px;
}
.areas ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 300px;
}

.areas li {
  flex: 1 0 11.1%; /* 100% divided by 9 columns */
  list-style: none;
  color: #444;
  font-size: 22px;
}


.experience  .heading{

  text-align: center;
  color: #a3a3a3;
  text-shadow: 0 5px 10px rgba(0,0,0,.2);
  font-size: 40px;
  text-transform:uppercase;
  padding-top: 100px;
}

.experience .row {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 200px;
  padding-top: 10px;
  
  
}

.rollsexperienceafe .row .image{
  flex: 1 1 45rem;
}

.experience .row .image .imggg{
  
  width:100%;
  height:600px;
  display: flex;
  margin-right: 10px;
  border-radius: 30px;
  
}
.experience .row .content{
  flex: 1 1 45rem;
  padding-right: 120px;
  font-size: 17.3px;
  
  
  
}



.CoursesContainer {
  display: flex;
  justify-content: center;
  padding:5%;
  margin-left: 10%;
}

.course {
  color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 20px;
  background: linear-gradient(145deg, #090100, #030000);
  box-shadow: 10px 10px 14px #626161, -10px -10px 14px #626161;
  width: 300px;
  margin: 10px;
  transition: transform 0.3s ease;
}

.course:hover {
  transform: translateY(-10px);
}
.containerManchester{
  padding: 15px 9%;
  padding-bottom: 100px;
}

.containerManchester .heading{

  text-align: center;
  padding-top: 30px;
  padding-bottom: 15px;
  color: #a3a3a3;
  text-shadow: 0 5px 10px rgba(0,0,0,.2);
  font-size: 50px;
}

.containerManchester .box-container {

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  gap: 15px
}

.containerManchester .box-container .box {
  display: flex;
  box-shadow: 0 5px 10px rgba(0,0,0,.2);
  background: #fff;
  text-align: center;
  padding: 30px 20px;

}

.containerManchester .box-container .box h3{
  color: #444;
  font-size: 22px;
  padding: 10px 0;
}

.containerManchester .box-container .box p{

  color: #444;
  font-size: 15px;
  line-height: 1.6;
}

.containerManchester .box-container .box .btn{
  margin-top: 10px;
  display: inline-block;
  background: #333;
  color: #fff;
  font-size: 17px;
  border-radius: 5px;
  padding: 8px 25px;
}

.containerManchester .box-container .box .btn:hover{
  letter-spacing: 1px;
}

.containerManchester .box-container .box:hover{
  box-shadow: 0 10px 15px rgba(0,0,0,.3);
  transform: scale(1.02);
}







.bottomContainer {
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 300px;
}

.bottomInfo {
  color: white;
  font-size: 15px;
  text-align: left;
  width: 100%;
  max-width: 500px;
  list-style: none;
  padding: 0;
  margin-top: 30px;
}

.bottomInfo li {
  margin-bottom: 20px;
}

.bottomInfo a {
  color: white;
  text-decoration: none;
}

.logoBottom {
  width: 250px;
  margin-top: 5px;
}

/* ---------------------------responsive------------------------------------ */
@media screen and (max-width: 600px)  {
  .CoursesContainer {
    flex-direction: column;
    align-items: center;
  }
  .course {
    width: 80%;
  }
  .header {
    flex-direction: column;
    align-items: center;
  }
  .social-media-top {
    margin:0.625em;
  }
  .logo{
    align-items: center;
    margin-left: 0;
    padding-left: 0;
  }
  .phoneNumber{
   margin-left: 3em;
   font-size: large;
  }
  .homeImgContainer{
    height:fit-content;
    width: 100%;
  }

  .heading{
    font-size:xx-large;
    padding: 0;
  }
  
  .about .row {
   flex-direction: column;
    align-items: center;
    background: var(black);
     
  }
  
  .about .row .image .imgg{
    width:85%;
    height:23.75em;
    display: flex;
    margin-left:1.5em;
    padding: 0.3125em;
    border-radius: 1.25em;
  }
  .about .row .content{
    padding: 0.3125em; 
  }
  .about .row .content h3{
    color: #444;
    font-size: 110%;
    margin-left: 10px;
    padding: 20px;
    padding-top: 0px;  
  }
  .about .row .content p{
    font-size: 80%;
    padding: 5px 20px;
    line-height: 2.0;
    
  }
  .about .row .content .btn{
    margin-top: 5px;
  }

  .containerManchester .heading{
    font-size:xx-large;
  }

  .areas .heading{
    font-size:xx-large;
    padding: 0;
  }

  .lists {
    flex-direction: column;
  }


  .areas li {
    flex: 1 0 50%; /* 100% divided by 9 columns */
    list-style: none;
    color: #444;
    font-size: 13px;
    text-align: left;
  }


  .experience .row {
    flex-direction: column;
    align-items: center;
    background: var(black);
    
    
  }

  .experience .row .image .imggg{
    width:300px;
    height:23.75em;
    display: flex;
    padding: 0.3125em;
    border-radius: 1.25em;
  }

  .experience .row .content{
    
    align-items: center;
    height: 20rem;
    width:fit-content;
    margin-left: 11rem;
  }


  .experience .row .content p{
  
    font-size: 70%;
    padding: 5px 5px;
    line-height: 1.4;
    width: 320%;
  }

.bottomContainer{
  flex-direction: column;
  gap: 5px;
  padding: 30px;
  
}
.bottomInfo {

  padding: 0;
  margin-top: 0px;
  margin-bottom: 10px;
}

.bottomInfo li {
  margin-bottom: 20px;
}

.bottomInfo a {
  color: white;
  text-decoration: none;
}

.logoBottom {
  width: 250px;
  margin-top: 0px;
}


.checkLocationButton{ 
  margin-left:20%;
}

.checkLocation{

  
  margin-left: 20%;
  
  }






}
/* ---------------------------End of responsive------------------------------------ */

.social-media {
  display: flex;
  justify-content: center;
  
}

.social-media a {
  margin: 0 10px;
  transition: transform 0.3s ease;
}

.social-media a:hover {
  transform: translateY(-5px);
}

.social-media img {
  width: 40px;
  height: 40px;
}



/*-------------------------------------------Review-----------------------------------------*/
.reviews-container {
 
  padding: 20px;
  
  color: #100f0f;
  text-align: center;
}

.reviews-header {
  margin-bottom: 20px;
}

.reviewsPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.featured-review {
  position: relative;
  margin-bottom: 20px;
}

.featured-review-img {
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.featured-review-img:hover {
  transform: scale(1.05);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.featured-review:hover .overlay {
  opacity: 1;
}

.overlay-text {
  color: white;
  font-size: 18px;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column; /* Updated to show all reviews in one column */
  align-items: center;
  margin-top: 20px;
  
 
}

.review-image {
  width: 100%;
  max-width: 800px;
  border-radius: 40px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  padding: 20px;
}

.review-image:hover {
  transform: scale(1.05);
}

 
/*---------------------------Pricing-------------------------------------------*/




/* Apply red, black, and white colors */
body {
  font-family: Arial, sans-serif;
  background-color: #fff; /* White background */
  color: #000; /* Black text color */
}

/* Main container styles */
.pricing-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Heading styles */
h2 {
  font-size: 2em;
  color: #d00; /* Red heading color */
  margin-bottom: 20px;
}

/* Pricing category styles */
.pricing-category {
  margin-bottom: 30px;
  border-bottom: 1px solid #d00; /* Red border */
  padding-bottom: 20px;
}

.pricing-category:last-child {
  border-bottom: none; /* Remove border from the last category */
}

/* Category title styles */
.pricing-category h3 {
  font-size: 1.5em;
  color: #000; /* Black text color */
  margin-bottom: 10px;
}

/* Pricing item styles */
.pricing-category ul {
  list-style-type: none;
  padding-left: 0;
}

.pricing-category ul li {
  font-size: 1.2em;
  color: #333; /* Dark gray text color */
  margin-bottom: 10px;
}

 
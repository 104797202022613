.form-container {
    width: 90%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-container h2 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .form-container form {
    margin-bottom: 30px;
    width: 100%;  
    padding: 10px;
    outline: 0;
    border: 1px solid #fff;
    color: #060606;
    background: transparent;
    font-size: 15px;
    position: relative;
  }
  
  .form-container label {
    margin-bottom: 30px;
    font-weight: bold;
    position: relative;
    font-size: 16px;

  }
  
  .form-container input[type="text"],
  .form-container input[type="date"],
  .form-container input[type="email"],
  .form-container input[type="tel"],
  .form-container select,
  .form-container textarea {
    width: 100%;
    padding: 10px;
    outline: 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .form-container select {
    height: 40px;
  }
  
  .form-container button[type="submit"] {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s;
  }
  
  .form-container button[type="submit"]:hover {
    background-color: #0056b3;
  }


  